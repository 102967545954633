import { type CartOperationResult, getCart } from './apis/cart/index.ts';
import fetchCartPhraseIfNeeded from './helpers/fetchCartPhraseIfNeeded.ts';
import { updateHeaderBadge } from './helpers/headerBadge.ts';
import { initOptimizely, optimizely } from './helpers/optimizely.ts';
import { country, language, shoppingCartUrl } from './helpers/settings.ts';
import {
    clearCartStore,
    fetchValidCartData,
    fetchValidCartItemsData,
    subscribeCart,
} from './helpers/store';
import {
    ADD_TO_CART,
    AGENT_LOADED,
    REMOVE_FROM_CART,
    UPDATE_CART,
    UPDATE_CART_COUNT,
    UPDATE_CART_ITEM_QUANTITY,
    UPDATE_DATA,
    publish,
} from './pubsub';
import type {
    CartEvent,
    CartPayload,
    RemoveFromCartEvent,
    RemoveFromCartPayload,
    UpdateCartCountEvent,
    UpdateCartCountPayload,
    UpdateDataEvent,
    UpdateDataPayload,
    UpdateQuantityEvent,
    UpdateQuantityPayload,
} from './types.ts';

export let cachedCartResult: CartOperationResult;
export const updateCachedCart = (cartResult: CartOperationResult) => {
    // Only update cached cart if in variation
    if (optimizely?.getVariation('flexi_cart_foundation') === 'on') {
        cachedCartResult = cartResult;
        updateHeaderBadge(cartResult.cart.quantity);
    }
};

const handleCartLinkClick = async (event: Event) => {
    const target = (event.target as Element).closest('a') as HTMLElement;
    if (target && target.getAttribute('href') === shoppingCartUrl) {
        event.stopPropagation();
        event.preventDefault();
        const module = await import('./handlers');
        module.handleOpenCartModal(cachedCartResult);
    }
};
const _handleAddToCartSubscription = async (payload: CartPayload) => {
    const module = await import('./handlers');
    await module.handleAddToCartSubscription(payload);
};

const _handleUpdateDataSubscription = async (payload: UpdateDataPayload) => {
    const module = await import('./handlers');
    module.handleUpdateDataSubscription(payload);
};

const _handleUpdateCartCountSubscription = async (
    payload: UpdateCartCountPayload
) => {
    const module = await import('./handlers');
    await module.handleUpdateCartCount(payload);
};

const _handleRemoveFromCartSubscription = async (
    payload: RemoveFromCartPayload
) => {
    const module = await import('./handlers');
    await module.handleRemoveItem(payload);
};

const _handleUpdateQuantitySubsription = async (
    payload: UpdateQuantityPayload
) => {
    const module = await import('./handlers');
    await module.handleUpdateQuantity(payload);
};

function initCartAgent() {
    if (window.ikea.shoppingCart === undefined) {
        window.ikea.shoppingCart = {
            getCart: fetchValidCartItemsData,
            getCartData: fetchValidCartData,
        };
    }
    window.ikea.shoppingCart.cartAgent = true;
    /**
     * Whenever the stored data is updated, we publish a pubsub event
     */
    subscribeCart(d => publish(UPDATE_CART, d));
    window.ikea.pubsub.subscribe<CartEvent>(
        ADD_TO_CART,
        _handleAddToCartSubscription
    );
    window.ikea.pubsub.subscribe<UpdateDataEvent>(
        UPDATE_DATA,
        _handleUpdateDataSubscription
    );
    window.ikea.pubsub.subscribe<UpdateCartCountEvent>(
        UPDATE_CART_COUNT,
        _handleUpdateCartCountSubscription
    );
    window.ikea.pubsub.subscribe<UpdateQuantityEvent>(
        UPDATE_CART_ITEM_QUANTITY,
        _handleUpdateQuantitySubsription
    );
    window.ikea.pubsub.subscribe<RemoveFromCartEvent>(
        REMOVE_FROM_CART,
        _handleRemoveFromCartSubscription
    );
}

export async function init() {
    if (window.ikea) {
        initCartAgent();
        await initOptimizely();
        // Only add listener outside cart page and feature is on
        if (
            optimizely?.getVariation('flexi_cart_foundation') === 'on' &&
            !window.location.pathname.includes('/shoppingcart')
        ) {
            //check if on cart then dont open
            await fetchCartPhraseIfNeeded();
            cachedCartResult = await getCart();
            // Listen for clicks on the cart link
            document.addEventListener('click', handleCartLinkClick, true);
        }
    }
    /**
     *  When a user leaves certain pages we need to clear the store, since it likely contains outdated information.
     * Example pages:
     *  - the login page, where another account might now be used
     */
    const clearStorePaths = [
        `/${country}/${language}/profile`,
        `/${country}/${language}/login`,
    ];
    if (clearStorePaths.some(path => window.location.pathname.includes(path))) {
        window.addEventListener('beforeunload', () => {
            clearCartStore();
        });
    }
    publish(AGENT_LOADED, null);
}
