import type { OptimizelyWrapper } from '../types.ts';
import { activeExperiments, optimizelyKey } from './settings.ts';

let optimizely: OptimizelyWrapper | null = null;

export const initOptimizely = async () => {
    try {
        if (optimizelyKey === '' || activeExperiments === '') {
            return;
        }
        const queryParameters = new URLSearchParams(window.location.search);
        const userId = queryParameters.get('opti_user_id');
        const options = {
            userId,
        };
        const attributes = {
            userId,
        };
        optimizely = await window.ikea.experiment.init(
            optimizelyKey,
            options,
            attributes
        );
        if (optimizely && activeExperiments) {
            optimizely.activate(activeExperiments);
        }
    } catch (e) {
        reportError(e as Error);
    }
};

export { optimizely };
